<template>
  <div class="main-container">
    <div class="main-card">
      <div class="header">
        <span class="font--white font--30 font-Bold">Welcome vExtract</span>
      </div>
      <div class="main-content">
        <div class="inner-card">
          <img class="inner-card-logo" src="..//assets/logo.png" />
          <span class="inner-card-text font-Medium font--48">vExtract</span>
        </div>
        <div class="login-button-container">
          <button class="bg--blue font--white font--24 font-SemiBold px-8 py-2" @click="signIn">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    async verifyUser(){
      const userData = {};
      if (
        sessionStorage.getItem("accessToken") 
        &&
        sessionStorage.getItem("account")
      ) {
        userData.account_identifier = JSON.parse(
          sessionStorage.getItem("account")
        ).localAccountId;
        userData.email = JSON.parse(
          sessionStorage.getItem("account")
        ).username
        let response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/verify-user",
          userData
        );
        if (response.data.data.valid == "True") {
          sessionStorage.setItem("userRole", response.data.data.role);
          this.$router.push('/form-queue');
        }
      } else {
        swal("User Authentication Failed!", "", "warning").then(() => {
          // localStorage.clear();
          sessionStorage.clear();
          this.$msal.logoutRedirect();
        });
      }
    },
    async signIn() {
      sessionStorage.clear();
      this.$msal
        .loginPopup({
          scopes: [`user.read`],
        })
        .then((res) => {
          sessionStorage.setItem("accessToken", res.accessToken);
          sessionStorage.setItem("account", JSON.stringify(res.account));
          sessionStorage.setItem("expiresTime", res.expiresOn);
          this.verifyUser();
        });
    },
  },
};
</script>

<style scoped src="../assets/styles/css/login.css"></style>
